import { css } from "@emotion/core"

export const globalStyles = css({
  html: {
    msTextSizeAdjust: "100%",
    WebkitTextSizeAdjust: "100%",
  },
  body: {
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    maxWidth: "1000px",
    margin: "0 auto",
    lineHeight: 1.3
  },
})
