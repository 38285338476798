import { FunctionComponent, Fragment } from "react"
import { Global, css } from "@emotion/core"
import { globalStyles } from "./globalStyles"
import { Link } from "gatsby"
import logo from "../../../images/ssbu-fire-square.jpg"

const navStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 10,
  marginRight: 10,
})

const navLogoStyles = css({
  height: 40,
  width: 40,
  marginRight: 10
})

const navHeaderStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

})

const navLinkSectionStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const navLinkStyles = css({
  marginLeft: 10,
  marginRight: 10,
})

export const Layout: FunctionComponent = ({ children }) => {
  return (
    <Fragment>
      <Global styles={globalStyles} />
      <div css={navStyles}>
        <h1 css={navHeaderStyles}><img src={logo} css={navLogoStyles}></img> Misfire 2</h1>
        <div css={navLinkSectionStyles}>
        <h3 css={navLinkStyles}><Link  to="/">Overview</Link></h3>
          <h3 css={navLinkStyles}><Link  to="/rules">Rules</Link></h3>
          <h3 css={navLinkStyles}><Link  to="/coin-flip">Coin Flip</Link></h3>
          <h3 css={navLinkStyles}><a href="https://smash.gg/tournament/ssbu-misfire-2" target="_blank">smash.gg</a></h3>
          <h3 css={navLinkStyles}><Link  to="/past-events">Past Events</Link></h3>
        </div>
      </div>
      <hr/>
      <div>{children}</div>
    </Fragment>
  )
}
