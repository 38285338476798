import React, { FunctionComponent } from "react"
import { Layout } from "../components/layout/Layout"

const CoinFlipPage: FunctionComponent = () => {
  const coinflip = Math.random() > 0.5 ? 'HEADS' : 'TAILS'

  return (
    <Layout>
      <h1>Coin Flip</h1>
      <div>Refresh the page for a random result.</div>
      <div>Time: {Date.now()}</div>
      <div>Result: <strong>{coinflip}</strong></div>
    </Layout>
  )
}

export default CoinFlipPage
